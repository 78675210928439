import React, {Component} from 'react';
import NowellonEditableField from "../NowellonEditableField";
import Picture from '../Shared/Picture';
import { LazyLoadComponent } from 'react-lazy-load-image-component';

class InovationSlider extends Component {
    constructor(props) {
        super(props);
        this.state = {translateX: 0, slider: 1, stan: 50};
    }
    next(event) {
        event.preventDefault();
        let item = document.getElementsByClassName("inno-slider")[0];
        this.setState({
            slider: this.state.slider + 1,
            stan: this.state.stan + 25
        }, () => {
            this.setState({
                translateX: "translateX(-"+ this.state.stan+ "%)",
            }, () =>{
                item.style.transform = this.state.translateX;
            })
        })
    }
    prev(event) {
        event.preventDefault();
        let item = document.getElementsByClassName("inno-slider")[0];
        this.setState({
            slider: this.state.slider - 1,
            stan: this.state.stan - 25
        }, () => {
            this.setState({
                translateX: "translateX(-"+ this.state.stan+ "%)",
            }, () =>{
                item.style.transform = this.state.translateX;
            })
        })
    }
    render() {
        return (
            <div className="innovative-slider">
                    <NowellonEditableField  FieldList={[this.props.discover[0].Title, this.props.discover[0].Subtitle, this.props.discover[0].MainDescription]}>
                    <section className="inno-slider-intro">
                        <div className="animate-ltr">
                            <h3 id="innovative-slider-h2" className="heading2" dangerouslySetInnerHTML={{"__html": this.props.discover[0].Title.Value}}></h3>
                        </div>
                        <p className="heading3" dangerouslySetInnerHTML={{"__html": this.props.discover[0].Subtitle.Value}}></p>
                        <p className="big-text" dangerouslySetInnerHTML={{"__html": this.props.discover[0].MainDescription.Value}}></p>
                    </section>
                    </NowellonEditableField>
                    <LazyLoadComponent height={this.props.mobileHeight}>
                        <div className="inno-slider-wrapper">
                            <ul className="inno-slider">
                                {this.props.discover[0].Signs.map((tile) => {
                                    return <NowellonEditableField key={tile.Name.Value}  FieldList={[tile.Image, tile.ImageAlt, tile.Name]}>
                                    <li className="inno-slider-item">
                                        <a href={tile.ImageUrl.Value}>
                                            <Picture
                                                src={tile.Image.Value}
                                                alt={tile.ImageAlt.Value}
                                                visibleByDefault={true}
                                                mobileWidth={this.props.mobileWidth}
                                                mobileHeight={this.props.mobileHeight}
                                            />
                                            <strong>{tile.Name.Value}</strong>
                                        </a>
                                    </li>
                                    </NowellonEditableField>
                                })}
                            </ul>
                            <nav className="inno-slider-nav">
                                {this.state.slider > 0 ? (<a href="" onClick={(event) => {this.prev(event)}} className="prev ir">prev</a>) : null}
                                {this.state.slider < 2 ? (<a href="" onClick={(event) => {this.next(event)}} className="next ir">next</a>) : null}
                            </nav>
                            <NowellonEditableField  FieldList={[this.props.discover[0].SideDescription, this.props.discover[0].ButtonText]}>
                            <aside className="inno-slider-aside">
                                <div dangerouslySetInnerHTML={{"__html": this.props.discover[0].SideDescription.Value}}></div>
                                <a href={this.props.discover[0].ButtonUrl.Value} className="button">{this.props.discover[0].ButtonText.Value}</a>
                            </aside>
                            </NowellonEditableField>
                        </div>
                    </LazyLoadComponent>
                </div>
        )
    }

}
export default InovationSlider;
