/* eslint new-cap: 0 */

"use strict";

import React, {Component} from "react";
import PropTypes from "prop-types";

class DocumentOverlay extends Component {
    getDefaultProps() {
        return {
            "className": "global-popin-wrapper"
        };
    }
    onClick(evt) {
        if (evt.currentTarget !== evt.target) {
            return;
        }

        this.props.OnClick(evt);
    }
    propTypes = {
        "className": PropTypes.string,
        "OnClick": PropTypes.func.isRequired
    }
    render() {
        return <div>
            <div className={this.props.className}>
                <div className="global-popin-scroller">
                    <div className="global-popin-centered" onClick={this.onClick} ref="overlay">
                        {this.props.children}
                    </div>
                </div>
            </div>
            <div className="global-popup-overlay js-overlay-visible" />
        </div>;
    }
}
export default DocumentOverlay;
