import React, {Component} from 'react';
import PlayerYoutube from './PlayerYoutube';
import NowellonEditableField from "../NowellonEditableField";
import Picture from './Picture';

class Teaser extends Component {
    constructor(props) {
        super(props);
        this.showHide = this.showHide.bind(this);
        this.state = {
            teaserId: null
        }
    }
    showHide(event, teaserID) {
        event.preventDefault();
        if(this.state.teaserId === teaserID) {
            this.setState({
                teaserId: ''
            })
        } else {
            this.setState({
                teaserId: teaserID
            })
        }
    }

    componentDidMount() {
        if (this.props.teaserClass === "teaser-product") {
            this.setState({
                teaserId: this.props.teaser[0].Id
            })
        }
    }
    getSocialMediaShareUrl = (type, url = "") => {
        if (type === "Facebook") {
            return `https://www.facebook.com/sharer.php?u=${url}`;
        } else if (type === "Twitter") {
            return `http://twitter.com/intent/tweet?url=${url}`;
        }
    };
    render() {
        return (
            <div id="section-teaser-list" className={"teaser-list " + this.props.teaserClass}>
            {this.props.teaser.map((teaser) => {
                    // const additionalClass = teaser.AdditionalClass !=="" ? teaser.AdditionalClass.Value : "";
                    const teaserActive = this.state.teaserId === teaser.Id ? "teaser active" : "teaser";
                    return <div key={teaser.Id} data-test="teaser-item" className={"teaserWrapper " + (teaser.AdditionalClass !=="" ? teaser.AdditionalClass.Value : "") + " " + (teaser.ImagePosition ? teaser.ImagePosition.Value: null) + " " + (teaser.TeaserType.Value === "True" ? "teaserReverseBox" : "")}>
                        {this.state.teaserId !== null ? (<h2 onClick={(event) => this.showHide(event, teaser.Id)} className={this.state.teaserId === teaser.Id ? ("heading3 title active") : ("heading3 title")}>{teaser.Subtitle.Value}</h2>) : null}
                        <div className={`${teaserActive}`}>
                            {teaser.YoutubeUrl.Value !== "" ? (
                                <PlayerYoutube youtubeUrl={teaser.YoutubeUrl.Value} teaserId={teaser.Id} teaser={teaser} teaserClass={this.props.teaserClass} />
                            ) : (
                                <NowellonEditableField  FieldList={[teaser.Image, teaser.ImageAlt]}>
                                    {teaser.LearnAboutTechnologies && teaser.LearnAboutTechnologies.length === 0 ? (
                                        <div className={"teaser-media shadow shadow-" + teaser.ShadowColour.Value}>
                                            <Picture src={teaser.Image.Value} alt={teaser.ImageAlt.Value} mobileHeight={this.props.mobileHeight} />
                                        </div>
                                    ) : null}
                                </NowellonEditableField>
                            )}
                            {teaser.LearnAboutTechnologies && teaser.LearnAboutTechnologies.length > 0 ? (
                                teaser.LearnAboutTechnologies.map((technology) => {
                                    return (
                                        <div className="accordion-desc">
                                            <h2 className="heading3">{technology.Title.Value}</h2>
                                            <Picture src={technology.Image.Value} alt={technology.ImageAlt.Value} />
                                            <div dangerouslySetInnerHTML={{"__html": technology.Subtitle.Value }}></div>
                                            <p dangerouslySetInnerHTML={{"__html": technology.Description.Value }}></p>
                                        </div>
                                    )
                                })
                            ) : null}

                            <NowellonEditableField  FieldList={[teaser.Title, teaser.Subtitle, teaser.Description, teaser.ButtonUrl, teaser.ButtonText]}>
                            <React.Fragment>
                                <article className={this.props.teaserClass === "success-stories" ? ("teaser-article shadow shadow-blue") : ("teaser-article shadow")}>
                                    {teaser.Title.Value !== "" && (
                                        <strong className="teaser-name" dangerouslySetInnerHTML={{"__html": teaser.Title.Value}}></strong>
                                    )}
                                    {teaser.Subtitle.Value !== "" ? (
                                        <h2 className="heading3" dangerouslySetInnerHTML={{
                                            "__html": teaser.Subtitle.Value
                                        }}></h2>
                                    ) : null}
                                    {teaser.Description.Value !== "" ?(
                                        <div dangerouslySetInnerHTML={{
                                            "__html": teaser.Description.Value
                                        }}></div>
                                    ) : null}
                                    {teaser.ButtonUrl.Value !== "" ? (
                                        <a href={teaser.ButtonUrl.Value} target={teaser.ButtonTarget.Value} className="button">{teaser.ButtonText.Value}</a>
                                    ) : null}
                                </article>
                                {teaser.ImagePosition.Value === "picture-video-teaser" ? (
                                    <div className={"teaser-innerPicture"}>
                                        <Picture src={teaser.Image.Value} alt={teaser.ImageAlt.Value} />
                                    </div>
                                ) : null}
                            </React.Fragment>
                            </NowellonEditableField>
                        </div>
                    </div>
                })}
            </div>
        )
    }
}

export default Teaser;
