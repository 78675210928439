const bindEvents = () => {
    console.log("nioxin analytics events bindEvents");
    window.addEventListener("DOMContentLoaded", () => {
        let body = document.getElementsByTagName("body")[0];
        body.addEventListener("MediaPlay", () => {});
        body.addEventListener("Media25", () => {});
        body.addEventListener("Media50", () => {});
        body.addEventListener("Media75", () => {});
        body.addEventListener("MediaComplete", () => {});
    });
};

const mediaCustomEvent = (eventName, videoName, videoLength, videoURL, videotimePlayed) => {
    let body = document.getElementsByTagName("body")[0];
    console.log("event Name", eventName);
    let videoMilestone = 0;
    let eventAction = "Video Media Play";
    switch (eventName) {
        case "MediaPlay":
            videoMilestone = 0;
            break;
        case "Media25":
            videoMilestone = 25;
            break;
        case "Media50":
            videoMilestone = 50;
            break;
        case "Media75":
            videoMilestone = 75;
            break;
        case "MediaComplete":
            videoMilestone = 100;
            eventAction = "Video Media Complete";
            break;
    }
    let event = new CustomEvent(eventName, {
        detail: {
            eventInfo: {
                eventAction: eventAction,
                eventName: eventName,
                eventType: "userEvent",
                video: {
                    videoName: videoName,
                    videoType: "youtube",
                    videoLength: videoLength,
                    videoURL: videoURL,
                    videotimePlayed: videotimePlayed,
                    videoMilestone: videoMilestone
                }
            }
        }
    });
    body.dispatchEvent(event);
};

export {
    bindEvents,
    mediaCustomEvent
};
