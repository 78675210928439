import React, {Component} from 'react';
import NowellonEditableField from "../NowellonEditableField";
import Picture from '../Shared/Picture';

class AdvancedSkin extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <div className="curiosities">
            <article className="curiosities-article">
                <h2 className="heading3">{this.props.widesection.Title.Value}</h2>
                <div dangerouslySetInnerHTML={{"__html": this.props.widesection.Content.Value }}></div>
                <Picture
                    src={this.props.widesection.Image.Value}
                    alt={this.props.widesection.ImageAlt.Value}
                    className="mrg-btm side-img"
                    mobileHeight={this.props.mobileHeight}
                />
                <br />
                {this.props.widesection.ButtonUrl.Value !== ""   ? (
                    this.props.widesection.ButtonText.Value !== "" ?
                    (<a href={this.props.widesection.ButtonUrl.Value} className="button">{this.props.widesection.ButtonText.Value}</a>):null) : null}
            </article>
            <NowellonEditableField  FieldList={[
                this.props.widesection.BackgroundImage,
                this.props.widesection.BackgroundImageAlt,
                this.props.widesection.Title,
                this.props.widesection.Content,
                this.props.widesection.Image,
                this.props.widesection.ImageAlt,
                this.props.widesection.ButtonText,
                this.props.widesection.ButtonUrl
                ]}>
            <figure className="curiosities-visual desktop-only">
            <Picture src={this.props.widesection.BackgroundImage.Value} alt={this.props.widesection.BackgroundImageAlt.Value} />
            </figure>
            </NowellonEditableField>
        </div>
        )
    }
}
export default AdvancedSkin;
