import React, {Component} from 'react';
import NowellonEditableField from "../NowellonEditableField";
import Picture from './Picture';

class Push extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <div className={"push-item " + this.props.tile.AdditionalClass.Value}>
                <div className="push-content">
                    {this.props.tile.Title.Value !== "" ?
                        <p className="big">{this.props.tile.Title.Value}</p>
                    :null}
                    {this.props.tile.Subtitle.Value !== "" ?
                        <p className="big" dangerouslySetInnerHTML={{"__html": this.props.tile.Subtitle.Value }}></p>
                    :null}
                    {this.props.tile.ButtonUrl.Value !== "" ?
                        <a href={this.props.tile.ButtonUrl.Value} className="button" target={this.props.tile.ButtonTarget && this.props.tile.ButtonTarget.Value ? this.props.tile.ButtonTarget.Value : "_self"}>{this.props.tile.ButtonText.Value}</a>
                    :null}
                </div>
                {this.props.tile.Image.Value !== "" ? (
                    <Picture src={this.props.tile.Image.Value} alt={this.props.tile.ImageAlt.Value} className="push-visual" />
                ) : null}
                <NowellonEditableField  FieldList={[
                this.props.tile.Title,
                this.props.tile.Subtitle,
                this.props.tile.ButtonUrl,
                this.props.tile.ButtonText,
                this.props.tile.Image,
                this.props.tile.ImageAlt
                ]}></NowellonEditableField>
            </div>
        )
    }
}

export default Push;
