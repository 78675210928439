"use strict";

import React, {Component} from "react";
import PropTypes from "prop-types";

class Line extends Component {
    getLineStyle() {
        return {
            "borderBottom": "none",
            "borderLeft": "none",
            "borderRight": "none",
            "borderTop": "1px solid rgba(11,98,120,0.9)",
            "height": 0,
            "left": 0,
            "margin": 0,
            "padding": 0,
            "pointerEvents": "none",
            "position": "absolute",
            "top": 0,
            "transform": "rotate(" + this.getLineAngle() + "deg)",
            "transformOrigin": "0 0",
            "width": this.getLineLength()
        };
    }
    getLineAngle() {
        return Math.atan2(this.props.EndY - this.props.StartY, this.props.EndX - this.props.StartX) * 180 / Math.PI;
    }
    getLineLength() {
        var xLength = this.props.EndX - this.props.StartX,
            yLength = this.props.EndY - this.props.StartY;

        return Math.sqrt(Math.pow(xLength, 2) + Math.pow(yLength, 2));
    }
    propTypes = {
        "EndX": PropTypes.number.isRequired,
        "EndY": PropTypes.number.isRequired,
        "StartX": PropTypes.number.isRequired,
        "StartY": PropTypes.number.isRequired
    }
    render() {
        return <hr style={this.getLineStyle()} />;
    }
};
export default Line;
