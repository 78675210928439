import React, { Component } from "react";
import HomePageContentCommon from "./HomePage/HomePageContentCommon";
import HomePageContentRU from "./HomePage/HomePageContentRU";
import { getOffsetTop } from "./utils";

// todo: move to cfg folder
const olapicCFG = {
    "en-EN": {
        instance: "110f8d1a61168bcd7f0f3fdc7b4a5df3",
        apiKey: "135cf8ab070fe535a7ad351ee4b448c0d30988b8a0eafefa8b20a48d7b4f5b6b",
    },
    "sv-SE": {
        instance: "ae363ce06b9114356019f623d6cd8360",
        apiKey: "d68ea6fa321fb0a7d8eaecc94eb4759fdb8c44a7c5484cc5916e65f28673d4f2",
        language: "sv_SE",
    },
    "nn-NO": {
        instance: "982a911698c30458613ef3083711d080",
        apiKey: "4cf6d9cdb98d3400f4bb8ca13f9d50585882bebb2525b1c0a21cc13c2b386da5",
        language: "nn_NO",
    },
    "en-GB": {
        instance: "ab3ccf879b522460c5b8a65671028197",
        apiKey: "29d0a3ea0f8931a2fe455373217c94ad08ea5cbe69842f410eb9fa8b556b4df0",
    },
    "fi-FI": {
        instance: "cac6909cacf1dea6a7b9cab9c7c53fbc",
        apiKey: "bf294a9aa96d537bacdf89fd4de31a5b81363e2aa2964bda6eeb75e027bc18c5",
        language: "fi_FI",
    },
    "da-DK": {
        instance: "13ba952acdc15263765233ad926f7bba",
        apiKey: "b38b128435ad09455d8df6fc9d762c1c96ca0d2a5a6c0a4d013b99ba936d3ac1",
        language: "dk_DK",
    },
    "it-IT": {
        instance: "af653fd4bf94d469f4fa8cd8a30221aa",
        apiKey: "c2831e71dce3f8faa44ea0554a895046abe82720e4a5d46675494d328677c238",
        language: "it_IT",
    },
    "es-ES": {
        instance: "295d6b7cabb71a19134a56541a21f176",
        apiKey: "4eecbdb3db2ea941d96482ac056a52c5a930629633c0cc0bc03a50221383dad1",
        language: "es_ES",
    },
    "el-GR": {
        instance: "fb49d0fbce67256c4d71ec2b1c173605",
        apiKey: "55bf2b38eae2c49b490f878ac73e74e1f342fd5f389e6c087eed654cd74db038",
        language: "el_GR",
    },
    "fr-FR": {
        instance: "3b29f930627cad8694bae0e4d9b9e898",
        apiKey: "472ab0e4a536e15f0cf012e35ff361f997201616b2b4e53112a6e60457e7844b",
        language: "fr_FR",
    },
    "ru-RU": {
        instance: "c7824072b16ed058284d79757b0d50bc",
        apiKey: "ae4041459b4247e8a2cf1629581aecd0fc39d7e361c3c20f7b778e6c951e0b43",
        language: "ru_RU",
    },
    "nl-NL": {
        instance: "c549ab27f9629b2c2cbb7994fa63aed1",
        apiKey: "338c18581960d4f08a3e380422626dde1a6ad74f3ef7db39d83ec19b9419292b",
        language: "nl_NL",
    },
    "pt-BR": {
        instance: "e0e794eac6c517b46bb473e23cbf877e",
        apiKey: "18c9f5fb5ca5ca6161ae53e0c2e0a009c2202c7e73525d50170a9aded79197d3",
        language: "pt_BR",
    },
    "de-DE": {
        instance: "9ae20b55caa9d9a6ef87af52b15b1feb",
        apiKey: "ab598fa954d1fec0049e419957bd5c39457a4391ef8776e6472d62cbce065082",
        language: "de_DE",
    },
    "en-US": {
        instance: "7c9192a36b5295acdf6ace09230cbf5b",
        apiKey: "a431ae1246f90725f5c7d1ee64a67f729adb89b4729998dba2e05df8a22a8c5d",
    },
};

class HomePageContend extends Component {
    constructor(props) {
        super(props);

        this.state = {
            countryCode: [],
            defaultCountry: null,
        };

        this.listener;
    }

    componentWillMount() {
        const countries = [];

        this.props.Countries.map((c) => {
            if (c.CurrentLanguage === true) {
                this.setState({
                    defaultCountry: c,
                });
            } else {
                countries.push(c);
            }
        });
        this.setState({
            countryCode: countries,
        });
    }

    componentDidMount() {
        this.startScrollPage();
    }

    addClassActive(index) {
        let AnimateElements = document.getElementsByClassName("animate-ltr ");
        if (AnimateElements[index].className === "animate-ltr") {
            AnimateElements[index].className += " active";
        }
    }
    isScrolledIntoView(elem) {
        const docViewTop = window.pageYOffset;
        const docViewBottom = docViewTop + window.innerHeight;
        const elemTop = getOffsetTop(elem);
        const elemBottom = elemTop + elem.clientHeight;

        return elemBottom <= docViewBottom && elemTop >= docViewTop;
    }

    // todo: this shouldnt work like this??
    animateProduct(elem) {
        const width = elem.offsetWidth;

        if (width) {
            elem.style.width = parseInt(width - 1.5) + "px";
        }
    }

    startScrollPage() {
        // todo: this shouldnt be picked by element, classes should handle elements + behaviour
        // todo: after animation is done element should be removed from arr, when arr is empty remove listener
        const elem = document.getElementById("product-favorties-hgroup");
        const elem2 = document.getElementById("innovative-slider-h2");
        const product = document.getElementsByClassName("rectangle");
        const elemArray = [elem, elem2, product[0], product[1], product[2]];

        const listener = () => {
            elemArray.map((elem, key) => {
                const result = this.isScrolledIntoView(elem);

                if (result) {
                    if (key > 1) {
                        this.animateProduct(elem);
                    } else {
                        this.addClassActive(key);
                    }
                }
            });
        };

        this.listener = listener;

        window.addEventListener("scroll", listener);
    }

    componentWillUnmount() {
        if (this.listener) {
            window.removeEventListener("scroll", this.listener);
        }
    }

    render() {
        return this.props.lang !== "ru-RU" ? (
            <HomePageContentCommon
                {...this.props}
                countryCode={this.state.defaultCountry.CountryCode}
            />
        ) : (
            <HomePageContentRU
                {...this.props}
                countryCode={this.state.defaultCountry.CountryCode}
            />
        );
    }
}

export default HomePageContend;
