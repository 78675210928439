import React, {Component} from 'react';
import NowellonEditableField from "../NowellonEditableField";
import Picture from '../Shared/Picture';

class ProductSection extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <div className="product-favorties">
                <NowellonEditableField  FieldList={[this.props.products[0].Title, this.props.products[0].Subtitle]}>
                    <hgroup id="product-favorties-hgroup" className="animate-ltr">
                        <strong className="name">{this.props.products[0].Title.Value}</strong>
                        <h2 dangerouslySetInnerHTML={{"__html": this.props.products[0].Subtitle.Value}}></h2>
                    </hgroup>
                </NowellonEditableField>
                <ul className="prod-favorit-list">
                    {this.props.products[0].Products.map((product, index) => (
                        <NowellonEditableField
                            key={product.ProductButtonUrl.Value}
                            FieldList={[
                                product.ProductImage,
                                product.ProductImageAlt,
                                product.ProductTitle,
                                product.ProductText,
                                product.ProductDescription,
                                product.ProductButtonUrl,
                                product.ProductButtonText
                            ]}
                        >
                            <li className={"prod-favorit-item prod"+ (index + 1)}>
                                <figure className="prod-favorit-visual">
                                    <Picture
                                        src={product.ProductImage.Value}
                                        alt={product.ProductImageAlt.Value}
                                    />
                                    <span className="rectangle"></span>
                                </figure>
                                <article className="prod-favorit-content">
                                    <h3 className="prod-favorit-name">{product.ProductTitle.Value}</h3>
                                    <p dangerouslySetInnerHTML={{"__html": product.ProductText.Value}}></p>
                                    <p className="italic-text">{product.ProductDescription.Value}</p>
                                    <a href={product.ProductButtonUrl.Value} className="button">{product.ProductButtonText.Value}</a>
                                </article>
                            </li>
                        </NowellonEditableField>
                    ))}
                </ul>
                <a href={this.props.products[0].DiscoverAllButtonUrl.Value} className="button inverse">{this.props.products[0].DiscoverAllButtonText.Value}</a>
            </div>
        )
    }
}
export default ProductSection;
