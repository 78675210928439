"use strict";

import PropTypes from 'prop-types';

export default PropTypes.shape({
    "FieldEditor": PropTypes.shape({
        "Class": PropTypes.string,
        "Display": PropTypes.string,
        "Id": PropTypes.string,
        "IsActive": PropTypes.bool.isRequired,
        "IsRoot": PropTypes.bool,
        "Type": PropTypes.string,
        "TypeName": PropTypes.string
    }).isRequired,
    "Value": PropTypes.any.isRequired
});
