import React from 'react';
import ReactDOM from 'react-dom';
import HomePageContend from './HomePageContend';

if (document.getElementById('homepage') !== null) {
    var homepage = JSON.parse(document.getElementById('homepage').textContent); // eslint-disable-next-line
    var instagram = JSON.parse(document.getElementById('instagram').textContent); // eslint-disable-next-line
    var salonCountries = JSON.parse(document.getElementById('salonCountries').textContent); // eslint-disable-next-line
    var layout = JSON.parse(document.getElementById('Layout').textContent); // eslint-disable-next-line
    var language = document.getElementById('divlang').getAttribute('data-id'); // eslint-disable-next-line
    ReactDOM.render(
        <HomePageContend
            {...layout.Model}
            homepage={homepage.Model}
            instagram={instagram}
            salonCountries={salonCountries}
            lang={language}
        />,
        document.getElementById('HomePageIndex')
    );
}
