"use strict";

/* eslint new-cap: 0 */

//import Draggabilly from "imports?define=>false!draggabilly";
import ElementOffset from "./ElementOffsetPropTypes";
import Line from "./Line";
import NowellonEditableFieldPropType from "./NowellonEditableFieldPropType";
import React, {Component} from "react";
import shouldComponentUpdate from "omniscient/shouldupdate";
import PropTypes from "prop-types";

class NowellonFieldEditor extends Component {
    draggabilly = null;
    minZIndex = 501;

    getEditButtonStyle() {
        return {
            "color": "rgb(245,230,60)",
            "cursor": "pointer",
            "fontSize": "12px",
            "lineHeight": "18px",
            "textDecoration": "none"
        };
    }
    getFirstEditButtonStyle() {
        return {
            "color": "rgb(245,230,60)",
            "cursor": "pointer",
            "fontSize": "12px",
            "lineHeight": "18px",
            "textDecoration": "none",
            "margin": "0 20px 0 0"
        };
    }
    getEditorStyle() {
        return {
            "backgroundColor": "rgba(11,98,120,0.9)",
            "borderRadius": "0px 5px 5px 5px",
            "cursor": "move",
            "padding": 5,
            "position": "relative",
            "pointerEvents": "auto"
        };
    }
    getEditorWrapperStyle() {
        return {
            "display": "block",
            "left": Math.round(this.props.Dimensions.Offset.Left),
            "position": "absolute",
            "top": Math.round(this.props.Dimensions.Offset.Top),
            "zIndex": Math.max(this.minZIndex, this.props.Dimensions.ZIndex + 1),
            "pointerEvents": "none"
        };
    }
    getInitialState() {
        return {
            "DragOffsetLeft": 0,
            "DragOffsetTop": 0
        };
    }
    onClick(EditableField, evt) {
        evt.preventDefault();

        this.props.OnClick(EditableField);
    }
    onDrag() {
        this.setState({
            "DragOffsetLeft": this.draggabilly.position.x,
            "DragOffsetTop": this.draggabilly.position.y
        });
    }
    getComponentInfo() {
        let childName = "";
        let parentName = "";
        let divider = "";
        if (this.props.info.child) {
            childName = this.props.info.child.name || ("<" + this.props.info.child + ">");
        }
        if (this.props.info.parent !== "CMS") {
            parentName = this.props.info.parent;
        }
        if (childName && parentName) {
            divider = " in ";
        }
        return childName + divider + parentName;

    }
    propTypes = {
        "Dimensions": PropTypes.shape({
            "Height": PropTypes.number.isRequired,
            "Offset": ElementOffset.isRequired,
            "Width": PropTypes.number.isRequired,
            "ZIndex": PropTypes.number.isRequired
        }),
        "FieldList": PropTypes.arrayOf(NowellonEditableFieldPropType).isRequired,
        "OnClick": PropTypes.func.isRequired
    }
    render() {
        return <div className="CMSFieldEditor" style={this.getEditorWrapperStyle()}>
            <Line
                EndX={this.props.Dimensions.Offset.Left + this.state.DragOffsetLeft}
                EndY={this.props.Dimensions.Offset.Top + this.state.DragOffsetTop}
                StartX={this.props.Dimensions.Offset.Left}
                StartY={this.props.Dimensions.Offset.Top}
            ></Line>
            <ul className="CMSFieldEditor__list" style={this.getEditorStyle()} ref="draggable">
                <li className="CMSFieldEditor__title" style={
                    {
                        color: "rgb(175, 239, 255)",
                        letterSpacing: "1px",
                        fontFamily: "monospace",
                        fontWeight: "normal",
                        border: "1px solid rgb(0, 74, 93)",
                        backgroundColor: "rgb(3, 83, 103)",
                        padding: "1px 0",
                        borderRadius: "2px",
                        textAlign: "center",
                        fontSize: "10.5px"
                    }
                }>{this.getComponentInfo()}</li> {// eslint-disable-line no-nested-ternary
                }{this.props.FieldList.map((EditableField, fieldIndex) => <li className="CMSFieldEditor__item" key={fieldIndex}>
                    <a style={(fieldIndex === 0) ? this.getFirstEditButtonStyle() : this.getEditButtonStyle()} onClick={this.onClick.bind(this, EditableField)}>
                        {EditableField.FieldEditor.Display}
                    </a>
                </li>)}
            </ul>
        </div>;
    }
    shouldComponentUpdate = shouldComponentUpdate
}
export default NowellonFieldEditor;
