import React, { Component } from "react";
import NowellonEditableField from "../NowellonEditableField";
import Picture from "../Shared/Picture";
import DetectBots from "../Shared/DetectBots";
import Recaptcha from "react-recaptcha";
import ReactPlayer from "react-player/file";

const CAPTCHA_KEY = "6Ld_T24UAAAAAIvg19HYa0I17AoxTmWDpJ57jeFF";

class HomePageSlider extends Component {
    constructor(props) {
        super(props);
        this.state = {
            translateX: 0,
            slider: 0,
            sliderLength: this.props.sliders.length,
            screenX: null,
            touchMove: false,
            days: 0,
            hours: 0,
            min: 0,
            sec: 0,
            submitClicked: false,
            email: "",
            emailError: null,
            showPopup: false,
            showSuccessPopup: false,
            videoLoad: null,
            terms: false,
            isCaptchaVerified: false,
        };
        this.recaptchaLoaded = this.recaptchaLoaded.bind(this);
        this.verifyReCaptcha = this.verifyReCaptcha.bind(this);
        this.expireReCaptcha = this.expireReCaptcha.bind(this);
    }
    next(event) {
        event.preventDefault();
        let item = document.getElementsByClassName("slider-list")[0];
        this.setState(
            {
                slider: this.state.slider + 1,
            },
            () => {
                this.setState(
                    {
                        translateX:
                            "translateX(-" + this.state.slider * 100 + "vw)",
                        touchMove: false,
                    },
                    () => {
                        item.style.transform = this.state.translateX;
                    }
                );
            }
        );
    }
    prev(event) {
        event.preventDefault();
        let item = document.getElementsByClassName("slider-list")[0];
        this.setState(
            {
                slider: this.state.slider - 1,
            },
            () => {
                this.setState(
                    {
                        translateX:
                            "translateX(-" + this.state.slider * 100 + "vw)",
                        touchMove: false,
                    },
                    () => {
                        item.style.transform = this.state.translateX;
                    }
                );
            }
        );
    }

    componentDidMount() {
        this.interval = setInterval(() => {
            const date = this.calculateCountDown();
            date ? this.setState(date) : this.stop();
        }, 1000);
        this.checkWidth();
    }

    componentWillUnmount() {
        this.stop();
    }

    addClassToBanner = () => {
        let bannerClass = this.props.sliders[0].SliderType
            ? this.props.sliders[0].SliderType.Value
            : "scalp-recovery";
        bannerClass = bannerClass.replace(/\s+/g, "-").toLowerCase();
        return bannerClass;
    };

    checkWidth = () => {
        if (window.innerWidth <= 768) {
            this.setState({
                videoLoad: "./../../i/banner/Banner_Video_M.mp4",
            });
        } else if (window.innerWidth <= 1025 && window.innerWidth > 768) {
            this.setState({
                videoLoad: "./../../i/banner/Banner_Video_T.mp4",
            });
        } else {
            this.setState({
                videoLoad: "./../../i/banner/Banner_Video_D.mp4",
            });
        }
    };
    calculateCountDown() {
        let difference =
            (Date.parse(new Date("2018-12-03")) - Date.parse(new Date())) /
            1000;

        if (difference <= 0) return false;

        const timeLeft = {
            days: 0,
            hours: 0,
            min: 0,
            sec: 0,
        };

        if (difference >= 86400) {
            timeLeft.days = Math.floor(difference / 86400);
            difference -= timeLeft.days * 86400;
        }
        if (difference >= 3600) {
            timeLeft.hours = Math.floor(difference / 3600);
            difference -= timeLeft.hours * 3600;
        }
        if (difference >= 60) {
            timeLeft.min = Math.floor(difference / 60);
            difference -= timeLeft.min * 60;
        }
        timeLeft.sec = difference;

        return timeLeft;
    }
    stop() {
        clearInterval(this.interval);
    }
    addLeadingZeros(value) {
        value = String(value);
        while (value.length < 2) {
            value = "0" + value;
        }
        return value;
    }
    handleEmail = (event) => {
        event.preventDefault();
        this.setState({ email: event.target.value });
    };
    validateEmail = () => {
        const EMAIL =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (this.state.email === "" || this.state.email === null) {
            this.setState({
                emailError: true,
            });
        } else if (!EMAIL.test(this.state.email)) {
            this.setState({
                emailError: true,
            });
        } else {
            this.setState({
                emailError: false,
            });
            this.togglePopup();
        }
    };
    togglePopup = () => {
        this.setState({
            showPopup: !this.state.showPopup,
        });
    };
    closeSuccessPopup = () => {
        this.setState({
            showSuccessPopup: false,
        });
    };
    handleCheckbox = (event) => {
        this.setState({
            checkbox: event.target.value,
            terms: !this.state.terms,
        });
    };
    checkStatus = (response) => {
        let error;
        if (response.status >= 200 && response.status < 300) {
            return response;
        }
        error = new Error(response.statusText);
        error.response = response;
        throw error;
    };
    parseJSON = (response) => {
        return response.json();
    };
    fetchData = (settings) => {
        let { url, options, onSuccess, onFail, body } = settings;
        fetch(url, {
            method: (options && options.method) || "POST",
            credentials: (options && options.credentials) || "same-origin",
            body: body,
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        })
            .then(this.checkStatus)
            .then(this.parseJSON)
            .then((data) => onSuccess(data))
            .catch((error) => onFail(error));
    };
    recaptchaLoaded() {
        // console.log('load');
    }
    verifyReCaptcha(response) {
        if (response) {
            this.setState({
                isCaptchaVerified: true,
            });
        } else {
            this.setState({
                isCaptchaVerified: false,
            });
        }
    }
    expireReCaptcha() {
        this.setState({
            isCaptchaVerified: false,
        });
    }
    sendForm = () => {
        this.setState({
            submitClicked: true,
        });
        let url = window.location.href;
        if (this.state.isCaptchaVerified === true) {
            this.fetchData({
                url: url,
                body: JSON.stringify({
                    Email: this.state.email,
                    TermsOpt: this.state.terms,
                }),
                onSuccess: this.handleSuccess,
                onFail: this.handleFail,
            });
            setTimeout(() => {
                this.setState({
                    isCaptchaVerified: false,
                });
            }, 2000);
        }
    };
    handleSuccess = (data) => {
        let IsSuccess = false;
        if ((data.IsSuccess = true)) {
            IsSuccess = true;
        }
        if (IsSuccess === true) {
            this.setState({
                showPopup: !this.state.showPopup,
                email: "",
                showSuccessPopup: true,
            });
        }
    };
    handleFail = (error) => {
        // console.log('Fail', error);
    };
    render() {
        return (
            <div className="main-slider">
                {this.props.lang.toLowerCase().indexOf("en-us-disabled") >
                -1 ? (
                    <section className="banner-section">
                        {this.props.sliders.map((slider) => {
                            return (
                                <div className="banner-container">
                                    <ReactPlayer
                                        url={this.state.videoLoad}
                                        className="banner-video article-video"
                                        loop
                                        playing
                                        playsinline
                                        muted
                                        height="100%"
                                    />
                                    <div className="banner-text side-left">
                                        <div className="banner-countdown">
                                            <p className="days">
                                                <span className="time-info">
                                                    {this.state.days === 1
                                                        ? "DAY"
                                                        : "DAYS"}
                                                </span>
                                                <span className="time-numbers">
                                                    {this.state.days > 0
                                                        ? this.addLeadingZeros(
                                                              this.state.days
                                                          )
                                                        : "00"}
                                                </span>
                                            </p>
                                            <p className="hours">
                                                <span className="time-info">
                                                    {this.state.hours === 1
                                                        ? "HOUR"
                                                        : "HOURS"}
                                                </span>
                                                <span className="time-numbers">
                                                    {this.state.hours > 0
                                                        ? this.addLeadingZeros(
                                                              this.state.hours
                                                          )
                                                        : "00"}
                                                </span>
                                            </p>
                                            <p className="minutes">
                                                <span className="time-info">
                                                    {this.state.min === 1
                                                        ? "MINUTE"
                                                        : "MINUTES"}
                                                </span>
                                                <span className="time-numbers">
                                                    {this.state.min > 0
                                                        ? this.addLeadingZeros(
                                                              this.state.min
                                                          )
                                                        : "00"}
                                                </span>
                                            </p>
                                            <p className="seconds">
                                                <span className="time-info">
                                                    {this.state.sec === 1
                                                        ? "SECOND"
                                                        : "SECONDS"}
                                                </span>
                                                <span className="time-numbers">
                                                    {this.state.sec > 0
                                                        ? this.addLeadingZeros(
                                                              this.state.sec
                                                          )
                                                        : "00"}
                                                </span>
                                            </p>
                                        </div>
                                        <h1
                                            dangerouslySetInnerHTML={{
                                                __html: slider.Title.Value,
                                            }}
                                        />
                                        <div className="banner-email">
                                            <input
                                                className={
                                                    this.state.emailError ===
                                                    true
                                                        ? "banner-email-error"
                                                        : null
                                                }
                                                onChange={this.handleEmail}
                                                name="email"
                                                value={this.state.email}
                                                type="text"
                                                placeholder="Enter your Email Address"
                                            />
                                            <button
                                                onClick={this.validateEmail}
                                            >
                                                SIGN UP
                                            </button>
                                        </div>
                                    </div>
                                    {this.state.showPopup ? (
                                        <div className="popup">
                                            <div className="popup-inner">
                                                <div className="popup-close">
                                                    <button
                                                        onClick={
                                                            this.togglePopup
                                                        }
                                                    >
                                                        X
                                                    </button>
                                                </div>
                                                <div className="popup-logo">
                                                    <Picture
                                                        src={
                                                            slider
                                                                .SliderPopup[0]
                                                                .LogoImage.Value
                                                        }
                                                        alt={
                                                            slider
                                                                .SliderPopup[0]
                                                                .LogoImageAlt
                                                                .Value
                                                        }
                                                    />
                                                </div>
                                                <div className="popup-images">
                                                    {slider.SliderPopup[0].Images.map(
                                                        (image) => {
                                                            return (
                                                                <img
                                                                    src={
                                                                        image
                                                                            .Image
                                                                            .Value
                                                                    }
                                                                    alt={
                                                                        image
                                                                            .ImageAlt
                                                                            .Value
                                                                    }
                                                                />
                                                            );
                                                        }
                                                    )}
                                                </div>
                                                <div className="popup-content">
                                                    <h2>
                                                        {
                                                            slider
                                                                .SliderPopup[0]
                                                                .Title.Value
                                                        }
                                                    </h2>
                                                    <span
                                                        dangerouslySetInnerHTML={{
                                                            __html: slider
                                                                .SliderPopup[0]
                                                                .SubTitle.Value,
                                                        }}
                                                    />
                                                    <hr />
                                                    <div className="popup-subscribe">
                                                        <input
                                                            value={
                                                                this.state
                                                                    .checkbox
                                                            }
                                                            onChange={
                                                                this
                                                                    .handleCheckbox
                                                            }
                                                            type="checkbox"
                                                        />
                                                        <div className="popup-subscribe-text">
                                                            <p
                                                                dangerouslySetInnerHTML={{
                                                                    __html: slider
                                                                        .SliderPopup[0]
                                                                        .SubscribeWillingness
                                                                        .Value,
                                                                }}
                                                                className="popup-subscribe-willingness"
                                                            />
                                                            <p
                                                                dangerouslySetInnerHTML={{
                                                                    __html: slider
                                                                        .SliderPopup[0]
                                                                        .SubscribeTermsAndPolicy
                                                                        .Value,
                                                                }}
                                                                className="popup-subscribe-terms"
                                                            />
                                                        </div>
                                                    </div>
                                                    <Recaptcha
                                                        sitekey={CAPTCHA_KEY}
                                                        render="explicit"
                                                        onloadCallback={
                                                            this.recaptchaLoaded
                                                        }
                                                        verifyCallback={
                                                            this.verifyReCaptcha
                                                        }
                                                        expiredCallback={
                                                            this.expireReCaptcha
                                                        }
                                                    />
                                                    {this.state
                                                        .submitClicked ===
                                                        true &&
                                                    this.state
                                                        .isCaptchaVerified ===
                                                        false ? (
                                                        <span className="error">
                                                            {
                                                                slider
                                                                    .SliderPopup[0]
                                                                    .RequiredMessage
                                                                    .Value
                                                            }
                                                        </span>
                                                    ) : (
                                                        <span className="error" />
                                                    )}
                                                    <button
                                                        onClick={this.sendForm}
                                                        className="popup-button"
                                                    >
                                                        {
                                                            slider
                                                                .SliderPopup[0]
                                                                .ButtonText
                                                                .Value
                                                        }
                                                    </button>
                                                    <p className="popup-star">
                                                        {
                                                            slider
                                                                .SliderPopup[0]
                                                                .Explanation
                                                                .Value
                                                        }
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    ) : null}
                                    {this.state.showSuccessPopup === true ? (
                                        <div className="popup">
                                            <div className="popup-inner">
                                                <div className="popup-close">
                                                    <button
                                                        onClick={
                                                            this
                                                                .closeSuccessPopup
                                                        }
                                                    >
                                                        X
                                                    </button>
                                                </div>
                                                <div className="popup-logo">
                                                    <Picture
                                                        src={
                                                            slider
                                                                .SliderPopup[0]
                                                                .LogoImage.Value
                                                        }
                                                        alt={
                                                            slider
                                                                .SliderPopup[0]
                                                                .LogoImageAlt
                                                                .Value
                                                        }
                                                    />
                                                </div>
                                                <div className="popup-images">
                                                    {slider.SliderPopup[0].Images.map(
                                                        (image) => {
                                                            return (
                                                                <img
                                                                    src={
                                                                        image
                                                                            .Image
                                                                            .Value
                                                                    }
                                                                    alt={
                                                                        image
                                                                            .ImageAlt
                                                                            .Value
                                                                    }
                                                                />
                                                            );
                                                        }
                                                    )}
                                                </div>
                                                <div className="popup-content">
                                                    <div className="success-popup-header">
                                                        <h2>
                                                            {
                                                                slider
                                                                    .SliderPopup[0]
                                                                    .ThankYou
                                                                    .Value
                                                            }
                                                        </h2>
                                                    </div>
                                                    <p className="success-popup-star popup-star">
                                                        {
                                                            slider
                                                                .SliderPopup[0]
                                                                .Explanation
                                                                .Value
                                                        }
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    ) : null}
                                </div>
                            );
                        })}
                    </section>
                ) : (
                    <div>
                        {this.props.lang
                            .toLowerCase()
                            .indexOf("de-de-disabled") > -1 ? (
                            <a href="https://wellastore.com/">
                                <ul className="slider-list">
                                    {this.props.sliders.map((slider) => {
                                        return (
                                            <li
                                                key={slider.Id}
                                                className={`slide ${
                                                    DetectBots() && "bot"
                                                }`}
                                            >
                                                <NowellonEditableField
                                                    FieldList={[
                                                        slider.SliderImage,
                                                        slider.SliderImageAlt,
                                                    ]}
                                                >
                                                    <Picture
                                                        src={
                                                            slider.SliderImage
                                                                .Value
                                                        }
                                                        alt={
                                                            slider
                                                                .SliderImageAlt
                                                                .Value
                                                        }
                                                    />
                                                </NowellonEditableField>
                                                <div className="slider-text side-left">
                                                    <section>
                                                        <h1
                                                            dangerouslySetInnerHTML={{
                                                                __html: slider
                                                                    .Title
                                                                    .Value,
                                                            }}
                                                        />
                                                        {slider.SliderButtons
                                                            .length > 0 ? (
                                                            <div className="buttons">
                                                                {slider.SliderButtons.map(
                                                                    (
                                                                        button
                                                                    ) => {
                                                                        return (
                                                                            <NowellonEditableField
                                                                                key={
                                                                                    button.Id
                                                                                }
                                                                                FieldList={[
                                                                                    button.ButtonTarget,
                                                                                    button.ButtonText,
                                                                                ]}
                                                                            >
                                                                                <a
                                                                                    href={
                                                                                        button
                                                                                            .ButtonTarget
                                                                                            .Value
                                                                                    }
                                                                                    className={
                                                                                        "button " +
                                                                                        button
                                                                                            .ButtonPosition
                                                                                            .Value
                                                                                    }
                                                                                >
                                                                                    {
                                                                                        button
                                                                                            .ButtonText
                                                                                            .Value
                                                                                    }
                                                                                </a>
                                                                            </NowellonEditableField>
                                                                        );
                                                                    }
                                                                )}
                                                            </div>
                                                        ) : null}
                                                    </section>
                                                </div>
                                            </li>
                                        );
                                    })}
                                </ul>
                            </a>
                        ) : (
                            <ul className="slider-list">
                                {this.props.sliders.map((slider, index) => {
                                    return (
                                        <li
                                            key={slider.Id}
                                            className={`slide ${
                                                DetectBots() && "bot"
                                            }`}
                                        >
                                            <NowellonEditableField
                                                FieldList={[
                                                    slider.SliderImage,
                                                    slider.SliderImageAlt,
                                                ]}
                                            >
                                                <Picture
                                                    src={
                                                        slider.SliderImage.Value
                                                    }
                                                    alt={
                                                        slider.SliderImageAlt
                                                            .Value
                                                    }
                                                    mobileWidth={360}
                                                    mobileHeight={638}
                                                />
                                            </NowellonEditableField>
                                            <div
                                                className={`slider-text ${this.addClassToBanner()} side-${slider.HorizontalTextPosition.Value}`}
                                            >
                                                <section>
                                                    {index === 0 ? (
                                                        <h1
                                                            dangerouslySetInnerHTML={{
                                                                __html: slider
                                                                    .Title
                                                                    .Value,
                                                            }}
                                                        />
                                                    ) : (
                                                        <h2
                                                            dangerouslySetInnerHTML={{
                                                                __html: slider
                                                                    .Title
                                                                    .Value,
                                                            }}
                                                        />
                                                    )}
                                                    {slider.SliderButtons
                                                        .length > 0 ? (
                                                        <div className="buttons">
                                                            {slider.SliderButtons.map(
                                                                (button) => {
                                                                    return (
                                                                        <NowellonEditableField
                                                                            key={
                                                                                button.Id
                                                                            }
                                                                            FieldList={[
                                                                                button.ButtonTarget,
                                                                                button.ButtonText,
                                                                            ]}
                                                                        >
                                                                            <a
                                                                                href={
                                                                                    button
                                                                                        .ButtonTarget
                                                                                        .Value
                                                                                }
                                                                                className={
                                                                                    "button " +
                                                                                    button
                                                                                        .ButtonPosition
                                                                                        .Value
                                                                                }
                                                                            >
                                                                                {
                                                                                    button
                                                                                        .ButtonText
                                                                                        .Value
                                                                                }
                                                                            </a>
                                                                        </NowellonEditableField>
                                                                    );
                                                                }
                                                            )}
                                                        </div>
                                                    ) : null}
                                                </section>
                                            </div>
                                        </li>
                                    );
                                })}
                            </ul>
                        )}
                        <nav className="slider-nav">
                            {this.state.slider > 0 ? (
                                <a
                                    onClick={(event) => {
                                        this.prev(event);
                                    }}
                                    href=""
                                    className="prev ir"
                                >
                                    prev
                                </a>
                            ) : null}
                            {this.state.sliderLength - 1 > this.state.slider ? (
                                <a
                                    onClick={(event) => {
                                        this.next(event);
                                    }}
                                    href=""
                                    className="next ir"
                                >
                                    next
                                </a>
                            ) : null}
                        </nav>
                    </div>
                )}
            </div>
        );
    }
}

export default HomePageSlider;
