import getStyleProperty from "desandro-get-style-property/get-style-property";
import NowellonEditableFieldPropType from "./NowellonEditableFieldPropType";
import NowellonFieldEditor from "./NowellonFieldEditor";
import NowellonFieldEditorDocumentOverlay from "./NowellonFieldEditorDocumentOverlay";
import React, { Component } from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";

function createContainer() {
    var container = document.createElement("div");

    container.style.position = "static";
    document.body.appendChild(container);

    return container;
}

class NowellonEditableField extends Component {
    componentDidMount() {
        const filterdValues = this.props.FieldList.filter(el => el);
        const hasValue = filterdValues.some(this.isFieldEditorActive);

        if (hasValue) {
            this.fieldEditorContainer = createContainer();
            this.popupContainer = createContainer();
        }
    }
    componentWillUnmount() {
        if (this.fieldEditorContainer) {
            ReactDOM.unmountComponentAtNode(this.fieldEditorContainer);
            this.fieldEditorContainer.parentElement.removeChild(this.fieldEditorContainer);
        }

        if (this.popupContainer) {
            ReactDOM.unmountComponentAtNode(this.popupContainer);
            this.popupContainer.parentElement.removeChild(this.popupContainer);
        }
    }
    getEffectiveZIndex() {
        var element = ReactDOM.findDOMNode(this),
            elementZIndex,
            zIndex = 0;

        while (element) {
            elementZIndex = parseInt(element.style[getStyleProperty("z-index")], 10);
            if (elementZIndex > zIndex) {
                zIndex = elementZIndex;
            }

            element = element.parentNode;
            if ("[object HTMLHtmlElement]" === Object.prototype.toString.call(element)) {
                // HTML root node fails at 'getComputedStyle'
                break;
            }
        }

        return zIndex;
    }
    isFieldEditorActive(field) {
        return field.FieldEditor.IsActive;
    }
    onEditableFieldClick(editableField) {
        ReactDOM.render(<NowellonFieldEditorDocumentOverlay
            Editor={editableField}
            OnClose={() => {
                ReactDOM.unmountComponentAtNode(this.popupContainer);
            }}
        ></NowellonFieldEditorDocumentOverlay>, this.popupContainer);
    }
    propTypes = {
        "FieldList": PropTypes.arrayOf(NowellonEditableFieldPropType).isRequired
    }
    render() {
        if (!this.props.children) {
            return <div data-info={"Empty " + this.constructor.displayName} />;
        }

        return React.Children.only(this.props.children);
    }
    renderIntoContainer(props, dimensions, done, info) {
        ReactDOM.render(<NowellonFieldEditor
            Dimensions={dimensions}
            FieldList={props.FieldList.filter(el => el)}
            OnClick={this.onEditableFieldClick}
            info={info}
        ></NowellonFieldEditor>, this.fieldEditorContainer, done);
    }
}
export default NowellonEditableField;
