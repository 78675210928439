import React, { Component } from "react";
import NowellonEditableField from "../NowellonEditableField";
import ReactPlayer from "react-player/youtube";
import { mediaCustomEvent } from "../VideoPlayer/analitycs";
import Picture from "./Picture";

const MILESTONE_POINTS = [25, 50, 75];

const getPercentage = (val, total) => parseInt((100 * val) / total);

export default class PlayerYoutube extends Component {
    milestoneTracker = {};
    currentMilestone = 0;

    constructor(props) {
        super(props);

        this.state = {
            showVideo: false,
        };

        this.videoRef = React.createRef();
    }

    getSocialMediaShareUrl = (type, url = "") => {
        if (type === "Facebook") {
            return `https://www.facebook.com/sharer.php?u=${url}`;
        } else if (type === "Twitter") {
            return `http://twitter.com/intent/tweet?url=${url}`;
        }
    };

    getCurrentVideoState = () => {
        if (!this.videoRef || !this.videoRef.current) {
            return;
        }

        return {
            duration: this.videoRef.current.getDuration(),
            secPlayed: this.videoRef.current.getCurrentTime(),
        };
    };

    buildVideoTrackingEvent = (eventName) => {
        const { duration, secPlayed } = this.getCurrentVideoState();

        if (!duration || !secPlayed) {
            console.error("Tracking failed: check video reference");

            return;
        }

        mediaCustomEvent(
            eventName,
            this.props.teaser.YoutubeTitle.Value,
            duration,
            this.props.youtubeUrl,
            secPlayed
        );
    };

    onStart = () => {
        this.buildVideoTrackingEvent("MediaPlay");
    };

    handleEnded = () => {
        this.buildVideoTrackingEvent("MediaComplete");
    };

    onProgress = () => {
        const { duration, secPlayed } = this.getCurrentVideoState();
        const milestone = getPercentage(secPlayed, duration);

        MILESTONE_POINTS.forEach((point) => {
            if (milestone >= point && !this.milestoneTracker[point]) {
                this.currentMilestone = point;
                this.milestoneTracker[point] = true;

                this.buildVideoTrackingEvent(
                    `Media${point !== 100 ? point : "Complete"}`
                );
            }
        });
    };

    showVideo = () => {
        this.setState({ showVideo: true });
    };

    render() {
        const { YoutubeImage, YoutubeImageAlt, WatchVideoText, YoutubeUrl } =
            this.props.teaser;

        const ShadowColour = this.props.teaser.ShadowColour
            ? this.props.teaser.ShadowColour
            : { Value: "", FieldEditor: { IsActive: false } };

        return (
            <div
                className={
                    this.props.teaserClass !== "success-stories"
                        ? "gradient-gray teaser-media video-wrapper shadow shadow-" +
                        ShadowColour.Value
                        : "gradient-gray teaser-media video-wrapper shadow"
                }
            >
                {(this.state.showVideo || !YoutubeImage.Value.length) && (
                    <ReactPlayer
                        id={"player-yt" + this.props.teaserId}
                        className="video video-js video-player"
                        url={
                            this.props.teaser.YoutubeUrl.Value +
                            "?rel=0&enablejsapi=1"
                        }
                        ref={this.videoRef}
                        onStart={this.onStart}
                        onProgress={this.onProgress}
                        onEnded={this.handleEnded}
                        playing
                        controls
                        className="video"
                        width="100%"
                        height="100%"
                        {...(!YoutubeImage.Value.length && {
                            light: true,
                            playIcon: (
                                <span className="button-play">
                                    {WatchVideoText
                                        ? WatchVideoText.Value
                                        : "Watch Video"}
                                </span>
                            ),
                        })}
                    />
                )}

                <NowellonEditableField
                    FieldList={[
                        ShadowColour,
                        YoutubeUrl,
                        YoutubeImage,
                        YoutubeImageAlt,
                    ]}
                ></NowellonEditableField>
                <ul className="socialbox">
                    <li>
                        <a
                            target="_blank"
                            href={this.getSocialMediaShareUrl(
                                "Facebook",
                                YoutubeUrl.Value
                            )}
                            className="ico-facebook ir"
                            title="facebook"
                            data-test="facebook-icon"
                        >
                            facebook
                        </a>
                    </li>

                    <li>
                        <a
                            target="_blank"
                            href={this.getSocialMediaShareUrl(
                                "Twitter",
                                YoutubeUrl.Value
                            )}
                            className="ico-twitter ir"
                            title="twitter"
                            data-test="twitter-icon"
                        >
                            twitter
                        </a>
                    </li>

                    <li>
                        <a
                            target="_blank"
                            href="https://www.instagram.com/nioxin/"
                            className="ico-instagram ir"
                            title="instagram"
                        >
                            instagram
                        </a>
                    </li>
                </ul>

                {!this.state.showVideo && YoutubeImage.Value.length > 0 && (
                    <div
                        onClick={(event) =>
                            this.showVideo(event, this.props.teaserId)
                        }
                        onKeyDown={(event) => {
                            if (event.key === 'Enter') {
                                this.showVideo(event, this.props.teaserId)
                            }
                        }}
                        href="#"
                        className="video-visual"
                        tabindex="0"
                    >
                        {this.props.teaser.YoutubeImage && (
                            <Picture
                                src={YoutubeImage.Value}
                                alt={YoutubeImageAlt.Value}
                                visibleByDefault={this.props.visibleByDefault}
                            />
                        )}
                        <span className="button-play">
                            {WatchVideoText
                                ? WatchVideoText.Value
                                : "Watch Video"}
                        </span>
                    </div>
                )}
            </div>
        );
    }
}
