import React from 'react';
import HomePageSlider from './HomePageSlider';
import InovationSlider from './InovationSlider';
// import ProductSection from './ProductSection';
import AdvancedSkin from './AdvancedSkin';
// import BottonPushes from '../Shared/BottonPushes';
// import SupportedBy from './SupportedBy';
// import BottonTile from './BottonTile';
import Teaser from '../Shared/Teaser';
import BottonPushes from '../Shared/BottonPushes';

const HomePageContentRU = (props) => {
    return (
        <div>
            <HomePageSlider sliders={props.homepage.Sliders} lang={props.lang} />
            <div className="main">
                <div id="product-favorties-hgroup" className="animate-ltr" />
                <AdvancedSkin widesection={props.homepage.WideSections[0]} />
                <InovationSlider discover={props.homepage.Discover} />
                <Teaser teaser={props.homepage.Teasers} teaserClass={''} />
                {/* <ProductSection products={props.homepage.ProductsIntroduction} /> */}
                {/* <SupportedBy widesection={props.homepage.WideSections[1]} /> */}
                {/*<BottonTile
                    bottonTile={props.homepage.BottomTiles}
                    instagram={props.instagram}
                />*/}
                <BottonPushes
                    salonFinder={props.homepage}
                    bottonPushes={props.homepage.BottomTiles}
                    salonCountries={props.salonCountries}
                />
            </div>
        </div>
    )
}

export default HomePageContentRU
