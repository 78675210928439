import React, {Component} from 'react';

class PushInstagram extends Component {
    constructor(props) {
        super(props);
        this.state = {
            instagramImages: this.props.instagram,
            instagramLength: this.props.instagram.length,
            instagramId: this.props.instagram[(this.props.instagram.length - 1)].Id,
            instagramNextId: this.props.instagram[(this.props.instagram.length - 2)].Id,
            pos : 1,
            intervalId: null,
            instagramCurrent: this.props.instagram.length - 1,
            instagramNext: this.props.instagram.length - 2
        };
    }
    componentDidMount() {
        var iCurrent = document.getElementById(this.props.instagram[(this.props.instagram.length - 1)].Id);
        iCurrent.className += " active";
        this.start();
    }
    start() {
        setTimeout(() => this.startInterval(), 10000);
    }
    nextImage() {
        this.setState({
            instagramNext: this.state.instagramNext - 1,
            instagramCurrent: this.state.instagramCurrent - 1,
        }, () => this.changeElem(this.state.instagramNext))
        setTimeout(() => this.startInterval(), 5000);
    }
    changeElem(elemid) {
        clearInterval(this.state.intervalId);
    }
    chengeOpacity(instagramCurrent, instagramNext) {
        var iCurrent = document.getElementById(instagramCurrent);
        var iNext = document.getElementById(instagramNext);
        if(iCurrent.style.opacity < 0){
            clearInterval(this.state.intervalId);
        }else{
            iCurrent.className = "push-instagram-item";
            iNext.className += " active";
            // iCurrent.style.opacity = 0;
            // iNext.className = 1;
            this.nextImage();
        }
    }
    startInterval = () => {
        if(this.state.instagramNext >= 0) {
            var instagramCurrent = this.props.instagram[this.state.instagramCurrent].Id;
            var instagramNext = this.props.instagram[this.state.instagramNext].Id;
            var intervalId = setInterval(() => {
                this.chengeOpacity(instagramCurrent, instagramNext);
            }, 1000);
            this.setState({ // eslint-disable-line
                intervalId: intervalId,
            });
        }else{
            clearInterval(this.state.intervalId);
        }
     }

    componentWillUnmount() {
        // use intervalId from the state to clear the interval
        clearInterval(this.state.intervalId);
    }
    render() {
        return (
            <div className="push-item push-instagram">
                <div className="top-bar">
                <img src="/m/homepage/nioxin-logo.png" alt="nioxin" />
                </div>
                <ul>
                {this.state.instagramImages.map((insta) => {
                    return <li key={insta.Id} className="push-instagram-item" id={insta.Id}>
                        <a href="https://www.instagram.com/nioxin/" target="_blank">
                            <img  src={insta.Image} className="push-visual" alt={insta.Text} />
                            <p className="heart">{insta.Likes}</p>
                        </a>
                    </li>
                })}
                </ul>
            </div>
        )
    }
}

export default PushInstagram;
