export const scrollTo = (to, duration = 1000) => {
    if (typeof to !== "number" || isNaN(to)) {
        return;
    }

    const element = document.scrollingElement || document.documentElement;
    const start = element.scrollTop;
    const change = to - start;
    const startDate = +new Date();

    const easeInOutQuad = (currentTime, start, change, duration) => {
        currentTime /= duration / 2;

        if (currentTime < 1) {
            return (change / 2) * currentTime * currentTime + start;
        }

        currentTime--;
        return (-change / 2) * (currentTime * (currentTime - 2) - 1) + start;
    };

    const animateScroll = () => {
        const currentDate = +new Date();
        const currentTime = currentDate - startDate;

        element.scrollTop = parseInt(
            easeInOutQuad(currentTime, start, change, duration)
        );

        if (currentTime < duration) {
            requestAnimationFrame(animateScroll);
        } else {
            element.scrollTop = to;
        }
    };

    animateScroll();
};

export const getOffsetTop = (elem) => {
    const box = elem.getBoundingClientRect();

    return (
        box.top +
        (window.pageYOffset || document.scrollTop) -
        (document.clientTop || 0)
    );
};
