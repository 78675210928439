import React, { Component } from 'react';
import BottonPusheSalonFinder from './BottonPusheSalonFinder';
import PushInstagram from '../Shared/PushInstagram';
import Push from 'Shared/Push';

class BottonPushes extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {salonCountries,
            countryCode,
            bottonPushes,
            instagram,
            buttonPusheClass,
            salonFinder
        } = this.props;
        const noSF = salonCountries && salonFinder.SalonFinderTile.length ? "" : "no-SF";
        return (
        <div className={`bottom-pushes ${noSF}`}>
            {this.props.salonCountries && this.props.salonFinder.SalonFinderTile.length && this.props.countryCode !== "ru-RU" ? (
                <BottonPusheSalonFinder
                    salonFinderTile={this.props.salonFinder.SalonFinderTile[0]}
                    salonCountries={this.props.salonCountries}
                />
            ) : null}
            {this.props.bottonPushes && this.props.bottonPushes.map((bottonPush, index) => (
                <Push key={index} tile={bottonPush} />
            ))}
            {this.props.instagram && this.props.instagram.length > 0 ? (
                <PushInstagram instagram={this.props.instagram} />
            ) : null}
        </div>
        );
    }
}
BottonPushes.defaultProps = {
    countryCode: 'en-EN'
};
export default BottonPushes;
