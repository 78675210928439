import React from 'react';
import HomePageSlider from './HomePageSlider';
import InovationSlider from './InovationSlider';
import ProductSection from './ProductSection';
import AdvancedSkin from './AdvancedSkin';
import BottonPushes from '../Shared/BottonPushes';
import SupportedBy from './SupportedBy';
//import BottonTile from './BottonTile';
import Teaser from '../Shared/Teaser';
import { LazyLoadComponent } from 'react-lazy-load-image-component';

const HomePageContentCommon = (props) => {
    return (
        <div>
            <HomePageSlider sliders={props.homepage.Sliders} lang={props.lang} />
            <div className="main">
                <LazyLoadComponent className="teaser-list" height={600}>
                    <Teaser teaser={props.homepage.Teasers} teaserClass={''} mobileHeight={300} />
                </LazyLoadComponent>
                <ProductSection products={props.homepage.ProductsIntroduction} />
                <InovationSlider discover={props.homepage.Discover} mobileHeight={147} />
                <AdvancedSkin widesection={props.homepage.WideSections[0]} mobileHeight={69} />
                <SupportedBy widesection={props.homepage.WideSections[1]} mobileHeight={88} />
                {/*
                <BottonTile
                    bottonTile={props.homepage.BottomTiles}
                    instagram={props.instagram}
                />
                */}
                {props.lang !== "ru-RU" ?
                    <BottonPushes
                        salonFinder={props.homepage}
                        bottonPushes={props.homepage.BottomTiles}
                        salonCountries={props.salonCountries}
                    /> : null
                }
            </div>
        </div>
    )
}

export default HomePageContentCommon
