// import $ from "jquery";
import DocumentOverlay from "./DocumentOverlay";
import NowellonEditableFieldPropType from "./NowellonEditableFieldPropType";
import React, { Component } from "react";

// todo: this doesnt work, restore and FIX later

class NowellonFieldEditorDocumentOverlay extends Component {
    componentDidMount() {
        // iframe onLoad is not enough for some reason
        // $(this.refs.frame).on("load", this.onFrameLoad);
    }
    getFrameUrl() {
        return nv.url("admin/pagecontent/edit/" + this.props.Editor.FieldEditor.Id + "?field=" + this.props.Editor.FieldEditor.TypeName + "&isroot=" + this.props.Editor.FieldEditor.IsRoot);
    }
    onFrameCloseClick(evt) {
        evt.preventDefault();

        this.props.OnClose();
    }
    onFrameLoad() {
        // var $frameDocument,
        //     angularScope,
        //     contentWindow = this.refs.frame.contentWindow;

        // $frameDocument = $(contentWindow.document);
        // angularScope = contentWindow.angular.element($frameDocument.find(".container").get(0)).scope();

        // angularScope.$on("saved", () => {
        //     if (window.location.hash) {
        //         window.location.reload(true);
        //     } else {
        //         window.location.href = window.location.href;
        //     }

        //     this.onFrameCloseClick();
        // });

        // $frameDocument.on("click", "#nowellon-pagecontent-popup-cancel", this.onFrameCloseClick);
        // $frameDocument.on("click", "#nowellon-pagecontent-popup-close", this.onFrameCloseClick);
    }
    propTypes = {
        "Editor": NowellonEditableFieldPropType.isRequired,
        "OnClose": React.PropTypes.func.isRequired
    }
    render() {
        return <DocumentOverlay className="global-popin-wrapper" OnClick={this.props.OnClose}>
            <iframe className="iframePopUp" onLoad={this.onFrameLoad} ref="frame" src={this.getFrameUrl()} />
        </DocumentOverlay>;
    }
}
export default NowellonFieldEditorDocumentOverlay;
