import React, { Component } from 'react';

class BottonPusheSalonFinder extends Component {
    constructor(props) {
        super(props);
        const lang = document.getElementById("divlang").getAttribute("data-id");

        this.state = {
            selectCountryCode: null,
            nioxinoldUrl: window.location.origin + "/" + lang + "/salon-finder?",
            zipcode: null
        };
        this.selectCountry = this.selectCountry.bind(this);
        this.findSalon = this.findSalon.bind(this);
        this.getZipCode = this.getZipCode.bind(this);
        this._handleKeyPress = this._handleKeyPress.bind(this);
    }
    selectCountry(event) {
        this.setState({
            selectCountryCode: event.target.value
        });
    }
    findSalon(event) {
        event.preventDefault();
        let sfCountryCode = "sfCountryCode";
        let sfSearchPhrase = "sfSearchPhrase"
        let url = this.state.nioxinoldUrl + sfCountryCode + "=" + this.state.selectCountryCode + "&" + sfSearchPhrase + "=" + this.state.zipcode;
        window.open(url, this.props.salonFinderTile.ButtonTarget && this.props.salonFinderTile.ButtonTarget.Value !== "" ? this.props.salonFinderTile.ButtonTarget.Value : "_self");
    }
    _handleKeyPress(event) {
        if (event.key === 'Enter') {
            this.findSalon(event);
        }
    }
    getZipCode(event) {
        event.preventDefault();
        this.setState({
            zipcode: event.target.value
        })
    }
    render() {
        return (
            <div className="push-item push-salon-finder" data-test="salonFinder-push">
                <div className="push-content">
                    <p>{this.props.salonFinderTile.Title.Value}</p>
                    <p className="big" dangerouslySetInnerHTML={{"__html": this.props.salonFinderTile.Subtitle.Value}}></p>
                    <div className="sf-push-country">
                        <label for="salonFinder_select">{this.props.salonFinderTile.CountryWatermark.Value}</label>
                        <select value={this.state.value} id="salonFinder_select" onChange={this.selectCountry}>
                            <option>{this.props.salonFinderTile.CountryWatermark.Value}</option>
                            {this.props.salonCountries.map((country) =>{
                                return <option key={country.Key} value={country.Key}>{country.Value}</option>
                            })}
                        </select>
                    </div>
                    <div className="sf-push-zip-code">
                        <label for="zip-code">{this.props.salonFinderTile.ZipWatermark.Value}</label>
                        <input onKeyPress={this._handleKeyPress} onChange={this.getZipCode} type="text" placeholder={this.props.salonFinderTile.ZipWatermark.Value} id="zip-code" />
                    </div>
                    <div>
                        <button
                            onClick={this.findSalon}
                            type="submit"
                            formTarget={this.props.salonFinderTile.ButtonTarget && this.props.salonFinderTile.ButtonTarget.Value !== "" ? this.props.salonFinderTile.ButtonTarget.Value : "_self"} className="button">{this.props.salonFinderTile.ButtonText.Value}
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}
export default BottonPusheSalonFinder;
