import React from 'react';
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import useMatchMedia from "hooks/useMatchMedia";

const Picture = (props) => {
    const {
        src,
        className,
        alt,
        visibleByDefault,
        mobileWidth,
        mobileHeight
    } = props;

    const getImageType = (image) => {
        if (/(\.|\,)png(--resize\,\d{2,}\,\d{2,})*$/i.test(image)) {
            return "image/png";
        }
        if (/(\.|\,)gif(--resize\,\d{2,}\,\d{2,})*$/i.test(image)) {
            return "image/gif";
        }
        if (/(\.|\,)jpg(--resize\,\d{2,}\,\d{2,})*$/i.test(image)) {
            return "image/jpeg";
        }
        if (/(\.|\,)webp(--resize\,\d{2,}\,\d{2,})*$/i.test(image)) {
            return "image/webp";
        }
        return "";
    }
    const isMobile = useMatchMedia('(max-width: 768px)');

    if (src.length > 0) {
        const mobileImage = src[1].Url;
        const tabletImage = src[2].Url;
        const desktopImage = src[0].Url;
        const mobileWebpImage = src[1].WebpUrl;
        const tabletWebpImage = src[2].WebpUrl;
        const desktopWebpImage = src[0].WebpUrl;

        return (
            <LazyLoadComponent visibleByDefault={visibleByDefault || false} height={isMobile ? mobileHeight : ""}>
                <picture>
                    {mobileWebpImage && (
                        <source
                            media="(max-width: 767px)"
                            srcSet={mobileWebpImage}
                            type={getImageType(mobileWebpImage)}
                        />
                    )}
                    {mobileImage && (
                        <source
                            media="(max-width: 767px)"
                            srcSet={mobileImage}
                            type={getImageType(mobileImage)}
                        />
                    )}
                    {tabletWebpImage && (
                        <source
                            media="(max-width: 1024px)"
                            srcSet={tabletWebpImage}
                            type={getImageType(tabletWebpImage)}
                        />
                    )}
                    {tabletImage && (
                        <source
                            media="(max-width: 1024px)"
                            srcSet={tabletImage}
                            type={getImageType(tabletImage)}
                        />
                    )}
                    {desktopWebpImage && (
                        <source
                            media="(min-width: 1025px)"
                            srcSet={desktopWebpImage}
                            type={getImageType(desktopWebpImage)}
                        />
                    )}
                    {desktopImage && (
                        <source
                            media="(min-width: 1025px)"
                            srcSet={desktopImage}
                            type={getImageType(desktopImage)}
                        />
                    )}
                    <img
                        src={desktopImage}
                        alt={alt}
                        className={className}
                        height={isMobile ? mobileHeight : ""}
                        width={isMobile ? mobileWidth : ""}
                    />
                </picture>
            </LazyLoadComponent>
        )
    }
    return false;
}
export default Picture;
